import React from "react"
import * as styles from "components/pc/topPage/index.module.scss"
import { StoreList } from "components/store/list"
import { StoreWrap } from "types/store";

type propsType = {
    stores: StoreWrap[] | []
}

const PcContents: React.FC<propsType> = (props: propsType) => {
    const stores = props.stores;
    return (
        <>
            <section className={styles.firstView}>
                <div className={styles.firstViewContents}>
                    <div className={styles.firstViewLeft}>
                        <div className={styles.imgWrap}>
                            <img
                                className={styles.deviseImg}
                                src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_app_display.png"
                                alt=""
                            />
                        </div>

                    </div>
                    <div className={styles.firstViewRight}>
                        <div className={styles.firstViewTitle}>
                            <img className={styles.titleSvg} src="https://balukoapplp.blob.core.windows.net/images/firstView/pc/blp_title.svg" alt="" />
                            <p className={`${styles.underText} fwn`}>バルコ ランドリープレイスアプリ</p>
                        </div>
                        <div className={styles.imgContainer}>
                            <div className={styles.imgItem}>
                                <div className={styles.topImgWrap}>
                                    <img
                                        className={styles.topImg}
                                        src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_text01.png"

                                        alt=""
                                    />
                                </div>
                                <div className={styles.underImgWrap}>
                                    <img
                                        className={styles.underImg}
                                        src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_img01.png"

                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className={styles.imgItem}>
                                <div className={styles.topImgWrap}>
                                    <img
                                        className={styles.topImg}
                                        src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_text02.png"

                                        alt=""
                                    />
                                </div>
                                <div className={styles.underImgWrap}>
                                    <img
                                        className={styles.underImg}
                                        src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_img02.png"

                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className={styles.imgItem}>
                                <div className={styles.topImgWrap}>
                                    <img
                                        className={styles.topImg}
                                        src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_text03.png"

                                        alt=""
                                    />
                                </div>
                                <div className={styles.underImgWrap}>
                                    <img
                                        className={styles.underImg}
                                        src="https://balukoapplp.blob.core.windows.net/images/firstView/fv_img03.png"

                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.firstViewFooter}>
                    <div className={styles.footerContents}>
                        <p className={`${styles.dlText} fwb`}>まずはアプリを無料ダウンロード</p>
                        <div className={styles.dlLinks}>
                            <div className={styles.imgWrap}>
                                <a href="https://apps.apple.com/jp/app/id1619758573?mt=8">
                                    <img
                                        src="https://balukoapplp.blob.core.windows.net/images/icon/btn_apple.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className={styles.imgWrap}>
                                <a href="https://play.google.com/store/apps/details?id=jp.baluko.app">
                                    <img
                                        src="https://balukoapplp.blob.core.windows.net/images/icon/btn_googleplay.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className={styles.about} id="about">
                <div className={styles.aboutContents}>
                    <h2 className={`${styles.title} en fwb`}>About</h2>
                    <p className={`${styles.jpTitle} fwb`}>アプリについて</p>
                    <div className={styles.inner}>
                        <div>
                            <img src="https://balukoapplp.blob.core.windows.net/images/gif/washing.gif" className={styles.gifImg} alt="" />
                        </div>
                        <ul className={`${styles.innerTextList} fwn`}>
                            <li><span>洗濯物をかかえて、スマホをポケットに入れランドリーへ。</span></li>
                            <li><span>100円玉がなくても、お財布を持たなくても困らない。</span></li>
                            <li><span>洗濯の間、お店を離れても、アプリが残り時間を教えてくれる。</span></li>
                            <li><span>Baluko Laundry Placeアプリで、</span></li>
                            <li><span>そんな新しいランドリー体験が始まります。</span></li>
                        </ul>
                    </div>
                </div>
            </section>

            <section className={styles.feature} id="feature">
                <div className={styles.featureContents}>
                    <h2 className={`${styles.title} en fwb`}>Features</h2>
                    <p className={`${styles.jpTitle} fwb`}>アプリでできること</p>
                    <div className={styles.inner}>
                        <div className={styles.innerItem}>
                            <p className={`${styles.innerItemTitle} fwn`}>店舗の空き状況がわかる</p>
                            <ul className={`${styles.innerItemTextList} fwn`}>
                                <li><span>店舗にいないくても、</span></li>
                                <li><span>ランドリー機器の空き状況がわかります。</span></li>
                            </ul>
                            <div className={styles.imgWrap}>
                                <img
                                    className={styles.innerItemImg}
                                    src="https://balukoapplp.blob.core.windows.net/images/feature/feature_img01.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className={styles.innerItem}>
                            <p className={`${styles.innerItemTitle} fwn`}>ランドリー機器を操作する</p>
                            <ul className={`${styles.innerItemTextList} fwn`}>
                                <li><span>QRコードを読み込んで操作を開始</span></li>
                                <li><span>コースや乾燥時間を選べます。</span></li>
                            </ul>
                            <div className={styles.imgWrap}>
                                <img
                                    className={styles.innerItemImg}
                                    src="https://balukoapplp.blob.core.windows.net/images/feature/feature_img02.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className={styles.innerItem}>
                            <p className={`${styles.innerItemTitle} fwn`}>キャッシュレスで決済</p>
                            <ul className={`${styles.innerItemTextList} fwn`}>
                                <li><span>クレジットカードまたはPayPayで決済。</span></li>
                                <li><span>キャッシュレスで洗濯／乾燥がスムーズに。</span></li>
                            </ul>
                            <div className={styles.imgWrap}>
                                <img
                                    className={styles.innerItemImg}
                                    src="https://balukoapplp.blob.core.windows.net/images/feature/feature_img03.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className={styles.innerItem}>
                            <p className={`${styles.innerItemTitle} fwn`}>洗濯が終わると通知が届く</p>
                            <ul className={`${styles.innerItemTextList} fwn`}>
                                <li><span>店舗から離れても、ご利用中の機器の状況を確認。</span></li>
                                <li><span>終了の通知も届いて安心です。</span></li>
                            </ul>
                            <div className={styles.imgWrap}>
                                <img
                                    className={styles.innerItemImg}
                                    src="https://balukoapplp.blob.core.windows.net/images/feature/feature_img04.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className={styles.innerItem}>
                            <p className={`${styles.innerItemTitle} fwn`}>お得な情報&クーポン</p>
                            <ul className={`${styles.innerItemTextList} fwn`}>
                                <li><span>登録した店舗のセール情報や</span></li>
                                <li><span>お得なクーポンが届きます。</span></li>
                            </ul>
                            <div className={styles.imgWrap}>
                                <img
                                    className={styles.innerItemImg}
                                    src="https://balukoapplp.blob.core.windows.net/images/feature/feature_img05.png"
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className={styles.innerItem}>
                            <p className={`${styles.innerItemTitle} fwn`}>領収書を受け取る</p>
                            <ul className={`${styles.innerItemTextList} fwn`}>
                                <li><span>ご利用履歴からいつでも領収書を</span></li>
                                <li><span>ダウンロードできます。</span></li>
                            </ul>
                            <div className={styles.imgWrap}>
                                <img
                                    className={styles.innerItemImg}
                                    src="https://balukoapplp.blob.core.windows.net/images/feature/feature_img06.png"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <StoreList stores={stores} />

            <section className={styles.install} id="install">
                <div className={styles.installContents}>
                    <h2 className={`${styles.title} en fwb`}>Install</h2>
                    <p className={`${styles.jpTitle} fwb`}>インストール</p>
                    <div className={styles.inner}>
                        <p className={`${styles.installStart} fwb`}>「Baluko Laundry Placeアプリ」をスタート</p>
                        <div className={styles.innerImages}>
                            <div className={styles.imgWrap}>
                                <a href="https://apps.apple.com/jp/app/id1619758573?mt=8">
                                    <img
                                        src="https://balukoapplp.blob.core.windows.net/images/icon/btn_apple.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className={styles.imgWrap}>
                                <a href="https://play.google.com/store/apps/details?id=jp.baluko.app">
                                    <img
                                        src="https://balukoapplp.blob.core.windows.net/images/icon/btn_googleplay.png"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <ul className={styles.infoTextList}>
                            <li><span>Appleのロゴは、米国もしくはその他の国や地域におけるApple Inc.の商標です。App Storeは、Apple Inc.のサービスマークです。</span></li>
                            <li><span>Google Play および Google Play ロゴは、Google LLC の商標です。</span></li>
                        </ul>
                    </div>
                </div>
            </section>

        </>
    );
}

export default PcContents;