import React from "react"
import Layout from "topPageLayout"
import PcContents from "components/pc/topPage/index"
import SpContents from "components/sp/topPage/index"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { useGetStores } from "hooks/useGetStores"
import { FilterAppStores } from "services/FilterAppStores"

// markup
const IndexPage: React.FC = () => {
  const stores = useGetStores();
  const appStores = FilterAppStores(stores);

  const breakPoints = useBreakpoint()
    return (
      <Layout>
        {breakPoints.pc &&
          <PcContents  stores={appStores}/>
        }
        {breakPoints.sp &&
          <SpContents  stores={appStores}/>
        }
      </Layout>
    )
}

export default IndexPage
