// extracted by mini-css-extract-plugin
export var about = "index-module--about--beb6b";
export var aboutContents = "index-module--aboutContents--e8e37";
export var comingSoon = "index-module--comingSoon--81c94";
export var deadLink = "index-module--deadLink--d350d";
export var dlLinks = "index-module--dlLinks--f3b1a";
export var dlText = "index-module--dlText--4d740";
export var feature = "index-module--feature--314b4";
export var featureContents = "index-module--featureContents--1b516";
export var firstView = "index-module--firstView--716cf";
export var firstViewContents = "index-module--firstViewContents--19598";
export var firstViewFooter = "index-module--firstViewFooter--253c4";
export var firstViewHeaderImg = "index-module--firstViewHeaderImg--2997c";
export var firstViewTitle = "index-module--firstViewTitle--1af82";
export var footerContents = "index-module--footerContents--b1c2f";
export var gifImg = "index-module--gifImg--74fa2";
export var imgContainer = "index-module--imgContainer--f2ec7";
export var imgItem = "index-module--imgItem--211b6";
export var imgWrap = "index-module--imgWrap--3d53d";
export var infoTextList = "index-module--infoTextList--89551";
export var inner = "index-module--inner--dab81";
export var innerImages = "index-module--innerImages--e05f3";
export var innerItem = "index-module--innerItem--784b6";
export var innerItemImg = "index-module--innerItemImg--2a270";
export var innerItemTextList = "index-module--innerItemTextList--3b326";
export var innerItemTitle = "index-module--innerItemTitle--27227";
export var innerTextList = "index-module--innerTextList--724bd";
export var install = "index-module--install--6410a";
export var installContents = "index-module--installContents--91051";
export var installStart = "index-module--installStart--3ea27";
export var jpTitle = "index-module--jpTitle--9de04";
export var leftImg = "index-module--leftImg--e09c6";
export var rightImg = "index-module--rightImg--05db1";
export var title = "index-module--title--16562";
export var titleSvg = "index-module--titleSvg--7a967";
export var topImg = "index-module--topImg--6c46d";
export var underImg = "index-module--underImg--97d55";
export var underImgWrap = "index-module--underImgWrap--d8474";
export var underText = "index-module--underText--e4f0e";